
import { ListingCard, PrimaryButton } from '../../../components';
import css from './SectionFandoms.module.scss';
import { useSelector } from 'react-redux';
import { getListingsById } from '../../../ducks/marketplaceData.duck';
import BlockContent from '@sanity/block-content-to-react';

interface IFandomCardProps {
  caption: string;
  text: string;
  btnText: string;
  btnUrl: string;
}

const ListingCardComp: any = ListingCard;

const FandomCard = ({ caption, text, btnText, btnUrl }: IFandomCardProps) => {
  return (
    <div className={css.fandomCard}>
      <h2>{caption}</h2>
      {text? <BlockContent blocks={text} /> : null}
      <div className={css.buttonWrapper}>
        <a href={btnUrl}>
          <PrimaryButton>
            {btnText}
          </PrimaryButton>
        </a>
      </div>
    </div>
  );
};

const SectionFandoms = () => {
  const { latestFandomListings, loadingInital, cmsData } = useSelector(
    (state: any) => state.LandingPage
  );
  const cmsContent = {
    caption: cmsData?.featuredProducts?.header,
    text: cmsData?.featuredProducts?.text,
    btnText: cmsData?.featuredProducts?.cta?.text,
    btnUrl: cmsData?.featuredProducts?.cta?.url,
  };

  const currentFandomIds = !loadingInital && latestFandomListings['cms'];
  const state = useSelector((state: any) => state);
  const listings = currentFandomIds ? getListingsById(state, currentFandomIds) : [];

  return (
    <div className={css.sectionContainer}>
      <div className={css.sectionContent}>
        <div className={css.fandomCardsContainer}>
          <FandomCard
            {...cmsContent}
          />
        </div>
        <div className={css.fandomListingsContainer}>
          {latestFandomListings &&
            listings.map((listing, index) => (
              <div className={css.listingCardWrapper} key={listing.id.uuid}>
                <ListingCardComp listing={listing} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SectionFandoms;
