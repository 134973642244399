import React from 'react';

import css from './SectionMobileApp.module.scss';
import { ReactComponent as QrCode } from '../assets/images/qr_code.svg';
import appleDownload from '../assets/images/apple_download.png';
import googleDownload from '../assets/images/google_download.png';
import { useSelector } from 'react-redux';

interface IProps {}

const SectionMobileApp = (props: IProps) => {
  const { cmsData } = useSelector((state: any) => state.LandingPage);

  return (
    <div className={css.sectionContainer}>
      <div className={css.sectionContent}>
        <div className={css.appInfoContainer}>
          <div className={css.appInfoWrapper}>
            <div className={css.qrCodeContainer}>
              <QrCode />
              <div className={css.label}>{cmsData?.mobileAppSection.qrLabel}</div>
            </div>
            <div className={css.infoBox}>
              <div className={css.infoCaption}>{cmsData?.mobileAppSection.header}</div>
              <div className={css.infoText}>{cmsData?.mobileAppSection.text}</div>
              <div className={css.downloadButtonsContainer}>
                <a href="https://apps.apple.com/us/app/fave-for-passionate-fans/id1558839284">
                  <img src={appleDownload} alt="Fave app Apple download button" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.faveforfans.fave&hl=en_US&gl=US">
                  <img src={googleDownload} alt="Fave app Google download button" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={css.appImageContainer}>
          <div className={css.imageWrapper}>
            <img src={cmsData?.mobileAppSection.image} alt="Fave mobile app" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionMobileApp;
