import React, { useState } from 'react';
import { PrimaryButton } from '../../../../components';
import DownloadAppModal from '../../../../components/DownloadAppModal/DownloadAppModal';
import css from './InfoBox.module.scss';
import BlockContent from '@sanity/block-content-to-react';
interface IProps {
  question: string;
  heading: string;
  text: string;
  buttonText: string;
  buttonLink?: string;
  downloadAppLink?: boolean;
  showButton?: boolean;
}

const InfoBox = (props: IProps) => {
  const [downloadAppModal, setDownloadAppModal] = useState(false);
  const handleModalDownloadApp = () => {
    setDownloadAppModal(prev => !prev);
  };
  const { question, heading, text, buttonText, buttonLink, downloadAppLink, showButton } = props;

  return (
    <div className={css.infoBoxContainer}>
      {question ? <div className={css.question}>{question}</div> : null}
      <h2 className={css.infoHeading}>{heading}</h2>
      <div className={css.infoText}>
        <BlockContent blocks={text} />
      </div>
      <div className={css.buttonContainer}>
        {showButton ? downloadAppLink ? (
          <PrimaryButton onClick={() => handleModalDownloadApp()}>{buttonText}</PrimaryButton>
        ) : (
          <a href={"/" + buttonLink} ><PrimaryButton>{buttonText}</PrimaryButton></a>
        ): null}
      </div>
      <DownloadAppModal isOpen={downloadAppModal} onClose={() => handleModalDownloadApp()} />
    </div>
  );
};

export default InfoBox;
