import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import css from './HeaderSlider.module.scss';
import classNames from 'classnames';

type SliderElement = { slide: (props: any) => ReactNode; props: any };

interface Props {
  sliderData: SliderElement[];
  /** In seconds */
  slideInterval?: number;
  infinite?: boolean;
}

const HeroImageSlider: FC<Props> = ({ sliderData, slideInterval, infinite = false }) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [rootWidth, setRootWidth] = useState(0);
  const [mouseOver, setMouseOver] = useState(false);

  const slide = (direction: -1 | 1): void => {
    if (direction > 0) {
      if (currentIndex >= sliderData.length - 1) {
        infinite && setCurrentIndex(0);
        return;
      }
      setCurrentIndex(currentIndex + 1);
    }
    if (direction < 0) {
      if (currentIndex < 1) {
        infinite && setCurrentIndex(sliderData.length - 1);
        return;
      }
      setCurrentIndex(currentIndex - 1);
    }
  };
  const slideCallback = useCallback(slide, [currentIndex, sliderData.length, infinite]);

  const keyHandlerCallback = useCallback(
    (event: KeyboardEvent) => {
      if (mouseOver && sliderData.length > 1) {
        if (event.code === 'ArrowRight') slideCallback(1);
        if (event.code === 'ArrowLeft') slideCallback(-1);
      }
    },
    [mouseOver, sliderData.length, slideCallback]
  );

  const disabledPrev = currentIndex == 0;
  const disabledNext = currentIndex >= sliderData.length - 1;
  const prev = classNames(css.controlItem, disabledPrev ? css.disabled : null);
  const next = classNames(css.controlItem, disabledNext ? css.disabled : null);

  useEffect(() => {
    // Adjust the width of the root container when the viewport is resized by the user
    // Needed to correctly slide to previous/next elements
    const imageRefCurrent = sliderRef.current;
    setRootWidth(imageRefCurrent.clientWidth);
    const resizeHandler = () => {
      setRootWidth(imageRefCurrent.clientWidth);
    };
    window.addEventListener('resize', resizeHandler);

    // Control slide behavior with arrow keys
    window.addEventListener('keydown', keyHandlerCallback);

    // Automatically slide to next element
    // The slideInterval prop must be set on the component
    const timer = slideInterval ? setInterval(() => slideCallback(1), slideInterval * 1000) : null;

    // Cleanup
    return () => {
      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener('keydown', keyHandlerCallback);
      timer && clearInterval(timer);
    };
  }, [slideCallback, keyHandlerCallback, slideInterval]);

  return (
    <motion.div
      className={css.root}
      ref={sliderRef}
      onHoverStart={() => setMouseOver(true)}
      onHoverEnd={() => setMouseOver(false)}
      onPanEnd={(event, info) => {
        if (info.velocity.x > 100) {
          slideCallback(-1);
        }
        if (info.velocity.x < -100) {
          slideCallback(1);
        }
      }}
    >
      <div className={css.sliderContent} style={{ left: -currentIndex * rootWidth }}>
        {sliderData.map((item, index) => {
          return (
            <div className={css.sliderItem} key={index}>
              {item.slide(item.props)}
            </div>
          );
        })}
      </div>
      {sliderData.length > 1 && (
        <div className={css.controls}>
          {
            <div className={prev} onClick={() => slide(-1)}>
              <IoIosArrowBack />
            </div>
          }
          <div className={next} onClick={() => slide(1)}>
            <IoIosArrowForward />
          </div>
          <div className={css.controlLinks}>
            {sliderData.map((item, index) => (
              <span
                key={index}
                onClick={() => setCurrentIndex(index)}
                style={{
                  backgroundColor:
                    index === currentIndex
                      ? 'var(--fave-color-purple-s500)'
                      : 'var(--fave-color-purple-s100)',
                }}
              />
            ))}
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default HeroImageSlider;
