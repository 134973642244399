import React, { Fragment } from 'react';

import css from './SectionCategories.module.scss';
import NamedLinkButton from '../../../components/NamedLink/NamedLinkButton/NamedLinkButton';
import { NamedLink } from '../../../components';
import { useSelector } from 'react-redux';

const AllCategoriesBox = ({ title }) => {
  return (
    <div className={css.allCategoriesBox}>
      <h1>{title}</h1>
    </div>
  );
};

interface ICategoryCardProps {
  image: string;
  label: string;
  search: string;
  altText?: string;
}

const CategoryCard = ({ image, label, search, altText }: ICategoryCardProps) => {
  return (
    <NamedLink name="SearchPage" to={{ search: search }} style={{ textDecoration: 'none' }}>
      <div className={css.categoryCard}>
        <div className={css.imgWrapper}>
          <img src={image} alt={altText ?? 'Fave image'} />
        </div>
        <h3 className={css.label}>{label}</h3>
      </div>
    </NamedLink>
  );
};

interface IProps {}

const SectionCategories = (props: IProps) => {
  const { cmsData } = useSelector((state: any) => state.LandingPage);

  return (
    <div className={css.sectionContainer}>
      <div className={css.sectionContent}>
        <div className={css.allCategoriesContainer}>
          <AllCategoriesBox title={cmsData?.categories?.header} />
        </div>
        <div className={css.categoriesLinksContainer}>
          <div className={css.categoryCardsContainer}>
            {cmsData?.categories?.categories.map(category => {
              return category ? (
                <Fragment key={category._key}>
                  <CategoryCard
                    image={category.image}
                    search={category.search}
                    label={category.title}
                  />
                </Fragment>
              ) : null;
            })}
          </div>
        </div>
        <div className={css.linkWrapper}>
          <NamedLinkButton name="SearchPage">See all products</NamedLinkButton>
        </div>
      </div>
    </div>
  );
};

export default SectionCategories;
