import React from 'react';
import classNames from 'classnames';

import css from './SectionHeader.module.scss';
import InfoBox from './InfoBox/InfoBox';
import HeroImageSlider from './HeaderSlider/HeaderSlider';
import { useSelector } from 'react-redux';

const HeaderSlide = (props: any) => {
  const infoBoxProps = {
    question: props.preHeader,
    heading: props.header,
    text: props.text,
    showButton: props.cta?.isVisible,
    buttonText: props.cta?.urlDescription,
    buttonLink: props.cta?.url,
    downloadAppLink: props.cta?.isDownloadApp,
  };

  const contentWrapper = classNames(
    css.contentWrapper,
    props.isReverse ? css.columnDirectionNormal : css.columnDirectionReversed
  );

  return (
    <div className={contentWrapper}>
      <div className={css.infoContainer}>
        <InfoBox {...infoBoxProps} />
      </div>
      <div className={css.illustrationOnlyContainer}>
        <img src={props.image} />
      </div>
    </div>
  );
};

interface IProps {}

const SectionHeader = (props: IProps) => {
  const { cmsData } = useSelector((state: any) => state.LandingPage);

  const slides = cmsData
    ? cmsData.headerSlides.map(slide => {
        return { props: slide, slide: HeaderSlide };
      })
    : [];
  return (
    <div className={css.root}>
      {slides.length > 0 ? <HeroImageSlider sliderData={slides} /> : null}
    </div>
  );
};

export default SectionHeader;
